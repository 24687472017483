import React, { useState } from "react";

/**
 * Hook que controla las alertas.
 */
export const useAlert = (): [(msg: string) => void, boolean, string] => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState<string>("");

  const setAlert = (msg: string) => {
    setAlertMsg(msg);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 3000);
  };

  return [setAlert, showAlert, alertMsg];
};
