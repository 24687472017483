import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { CustomForm, CustomButton, CustomAlert } from "../../components";
import { useNavigate } from "react-router-dom";
import { config } from "../../config";
import { useFetchProduct, useGetClientData } from "../../hooks";

import "../../styles/Code.css";

export interface Props {}

/**
 * Screen que permite obtener productos colocando el codigo de manera manual
 */
export const CodeScreen: React.FunctionComponent<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [productCode, setProductCode] = useState<string>("");
  const [dataStored, getClientData] = useGetClientData();
  const [fetchProduct, showAlert, alertMsg] = useFetchProduct();

  return (
    <div className="border">
      <header className="client-logo-header">
        <div className="client-logo-row">
          <Image
            className="client-logo"
            src={`${config.API.URL}/images/${dataStored.name}.png`}
            onError={(e) => {
              e.currentTarget.src = `${config.API.URL}/images/logodefault.png`;
            }}
          />
        </div>
      </header>
      <div className="code-container">
        <CustomAlert variant="danger" show={showAlert} content={alertMsg} />
        <div className="form-row">
          <CustomForm
            className="form"
            label="Ingresa el código para consultar su precio"
            placeHolder="Código"
            text={productCode}
            setText={setProductCode}
          />
        </div>
        <hr className="divider"></hr>
        <div className="back-btn-row">
          <CustomButton
            className="back-btn"
            style={{ fontSize: 20, maxWidth: 160 }}
            click={() => navigate(`/home`)}
            show={true}
            content="Regresar"
          />
          <CustomButton
            className="price-button"
            style={{ fontSize: 20, maxWidth: 220 }}
            click={() => {
              fetchProduct(productCode);
            }}
            show={true}
            content="Consultar precio"
            disabled={showAlert}
          />
        </div>
      </div>
    </div>
  );
};
