import React, { useEffect } from "react";
import { CodeScanner, CustomAlert, CustomButton } from "../../components";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { config } from "../../config";
import { useGetClientData, useFetchProduct, useDecode } from "../../hooks";
import "../../styles/Home.css";

export interface Props {}

/**
 * Screen principal
 */
export const HomeScreen: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [dataStored, getClientData] = useGetClientData();
  const [fetchProduct, showAlert, alertMsg] = useFetchProduct();

  const navigate = useNavigate();
  const [decodeOnce] = useDecode(fetchProduct);

  useEffect(() => {
    decodeOnce();
  }, []);

  return (
    <div className="border">
      <header className="client-logo-header">
        <Image
          className="client-logo"
          src={`${config.API.URL}/images/${dataStored.name}.png`}
          onError={(e) => {
            e.currentTarget.src = `${config.API.URL}/images/logodefault.png`;
          }}
        />
      </header>
      <div className="home-container">
        <CustomAlert variant="danger" show={showAlert} content={alertMsg} />
        {/* <hr className="divider"></hr> */}

        {/* <div className="scanner-container-row"> */}
        <div className="scanner-text">
          <p>Para ver el precio escaneá el código</p>
        </div>
        <div className="scanner-row">
          <hr className="camera-indicator"></hr>
          <CodeScanner
            className="code-scanner"
            height={350}
            width={320}
          />
        </div>
        <hr className="divider"></hr>
        <div className="manual-button">
          <CustomButton
            style={{ fontSize: 20 }}
            content={`Ingrese el código del producto`}
            click={() => navigate(`/manualcode`)}
            show={true}
            className="code-btn"
            // variant="light"
          />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};
