import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomeScreen, CodeScreen, ProductScreen } from "../screens";
import {
  CityKidsCodeScreen,
  CityKidsHomeScreen,
  CityKidsProductScreen,
} from "../screens/Clients";
import ClientContext from "../context/ClientContext";

export interface Props {}

export const AppNavigation: React.FunctionComponent<Props> = (props: Props) => {
  const [clientData] = useContext(ClientContext);

  // agregar si es que habran mas clientes
  return (
    <Router>
      {clientData.name == "citykids" ? (
        <Routes>
          <Route path="/*" element={<CityKidsHomeScreen />} />
          <Route path="/" element={<CityKidsHomeScreen />} />
          <Route path="/home" element={<CityKidsHomeScreen />} />
          <Route path="/manualcode" element={<CityKidsCodeScreen />} />
          <Route path="/product" element={<CityKidsProductScreen />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/*" element={<HomeScreen />} />
          <Route path="/" element={<HomeScreen />} />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/manualcode" element={<CodeScreen />} />
          <Route path="/product" element={<ProductScreen />} />
        </Routes>
      )}
    </Router>
  );
};
