import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { CustomButton } from "../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { parsePriceStr } from "../../utils/utils";
import { StockVisualizer } from "../../components";
import { config } from "../../config";
import { BsArrowDownSquareFill } from "react-icons/bs";
import { useGetClientData } from "../../hooks";

import "../../styles/Product.css";

export interface Props {}

/**
 * Screen donde se muestra la informacion del producto
 */
export const ProductScreen: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [noProdImage, setNoProdImage] = useState<boolean>(false);
  const [scrollBtn, setScrollBtn] = useState<boolean>(true);
  const [dataStored, getClientData] = useGetClientData();

  const navigate = useNavigate();

  const {
    state: { data },
  } = useLocation();

  const handleScroll = () => {
    setScrollBtn(false);

    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="product-container">
      <div className="prod-container">
        {!noProdImage ? (
          <Image
            className="prod-image"
            src={`${config.CLIENTS.URLIMG}/${data.sku}.jpg`}
            onError={(e) => {
              setNoProdImage(true);
            }}
          />
        ) : (
          <header>
            <Image
              className="client-logo"
              src={`${config.API.URL}/images/${dataStored.name}.png`}
              onError={(e) => {
                e.currentTarget.src = `${config.API.URL}/images/logodefault.png`;
              }}
            />
          </header>
        )}
      </div>
      <div className="prod-data">
        <p className="prod-name-text">{data.name}</p>
        <p className="prod-data-text">{data.brand}</p>
        <p className="prod-data-text">{`SKU: ${data.sku}`}</p>
      </div>
      <div className="prod-price">
        <p>${parsePriceStr(parseFloat(data.price).toFixed(2))}</p>
      </div>
      {data.stock !== undefined ? (
        <div className="prod-stock">
          <p className="stock-title">
            Disponibilidad en nuestras tiendas
          </p>
          <StockVisualizer className="stock-visualizer" data={data} />
          <p className="stock-text">
            Stock sujeto a confirmación. Verificar disponibilidad con personal
            de la sucursal.
          </p>
        </div>
      ) : null}
      <hr className="divider"></hr>
      <div className="prod-back-btn-row">
        <CustomButton
          className="prod-back-btn"
          content="Consultar otro producto"
          show={true}
          click={() => navigate(`/home`)}
          style={{ fontSize: 20 }}
        />
      </div>
      {scrollBtn ? (
        <footer className="scrool-down-footer">
          <div className="scroll-down-btn-container">
            <BsArrowDownSquareFill
              className="btn-icon"
              onClick={handleScroll}
            />
          </div>
        </footer>
      ) : null}
    </div>
  );
};
