import React from "react";

export interface Props {
  width: number;
  height: number;
  className?: string;
}
export const CodeScanner: React.FunctionComponent<Props> = (props: Props) => {

  return(
    <div>
      <video id="video" width={props.width} height={props.height}></video>
    </div>
  );
};
