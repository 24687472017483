import React from "react";
import { ConfigService } from "../services";
import { isEmpty } from "../utils/utils";

/**
 * Hook que se encarga de fetchear la info del cliente.
 */
export const useGetClientData = () => {

  const getClientFromURL = () => {
    const pathname: Array<string> = window.location.pathname.split("/");
    const host: Array<string> = window.location.host.split(".");

    if (host[1] == "test") return pathname[1];
    else return host[1];
  };

  const getClientData = () => {
    let name: string = getClientFromURL();

    return new Promise<any>((resolve, reject) => {
      ConfigService.get(name)
        .then((data) => {
          if (!isEmpty(data)) {
            data.name = data.name.toLowerCase();
            resolve(data);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  return [getClientData];
};
