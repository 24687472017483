import React from "react";
import Button from "react-bootstrap/Button";
import { FaGreaterThan } from "react-icons/fa";

export interface Props {
  click: () => void;
  content: any;
  show?: boolean;
  className?: string;
  disabled?: boolean;
  size?: "sm" | "lg";
  style?: {};
  variant?: string;

}

export const CustomButton: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Button
      size={props.size}
      className={props.className}
      style={{ ...props.style, borderRadius: 10 }}
      onClick={props.click}
      disabled={props.disabled}
      variant={props.variant}
    >
      {props.content}
    </Button>
  );
};
