import React, { createContext, useLayoutEffect, useState } from "react";
import { useGetClientData } from "../hooks";
import { ConfigService } from "../services";
import { isEmpty } from "../utils/utils";

export interface Props {
  children: any;
}

const ClientContext = createContext<any>([]);

export const ClientProvider = ({ children }: Props) => {
  const [clientData, setClientData] = useState<any>("");
  const [getClientData] = useGetClientData();

  useLayoutEffect(() => {
    getClientData()
      .then((data: any) => setClientData(data))
      .catch((error: any) => console.error(error));
  }, []);

  return (
    <ClientContext.Provider value={[clientData, setClientData]}>
      {children}
    </ClientContext.Provider>
  );
};

export default ClientContext;
