import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ProductsService } from "../services";
import { isEmpty } from "../utils/utils";
import { useGetClientData, useAlert } from ".";
import ClientContext from "../context/ClientContext";

/**
 * Custo hook que nos permite fetchear productos y mostrar alertas dependiendo de la respuesta
 */
export const useFetchProduct = (): [
  (productCode: string) => void,
  boolean,
  string
] => {
  const [setAlert, showAlert, alertMsg] = useAlert();
  const [clientData] = useContext(ClientContext);
  const navigate = useNavigate();

  const fetchProduct = (productCode: string) => {
    if (productCode.length === 0) setAlert("Ingrese el codigo de un producto.");
    else
      return new Promise<any>((resolve, reject) => {
        ProductsService.get(
          clientData.clientId.toString(),
          clientData.token,
          productCode
        )
          .then((data) => {
            if (!isEmpty(data)) {
              resolve(data);
              navigate(`/product`, {
                state: { data },
              });
            } else {
              setAlert("Producto no disponible.");
              reject("Producto no disponible");
            }
          })
          .catch((error) => {
            console.log(error);
            setAlert(
              "Tenemos problemas con el servidor. Lo solucionaremos a la brevedad."
            );
            reject("Problemas con el servidor");
          });
      });
    // } else {
    //   setAlert(
    //     "Tenemos problemas con el servidor. Lo solucionaremos a la brevedad."
    //   );
    // }
  };

  return [fetchProduct, showAlert, alertMsg];
};
