import React, { useState } from "react";

/**
 * Hook que facilita el uso de local storage.
 */
export const useLocalStorage = (key: string, initialValue: any) => {
  const [dataStored, setDataStored] = useState(() => {
    try {
      const value = window.localStorage.getItem(key);
      return value ? JSON.parse(value) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const getData = () => {
    setDataStored(window.localStorage.getItem(key));
  }

  const storeData = (value: any) => {
    try {
      setDataStored(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(error);
    }
  };

  return [dataStored, storeData, getData];
};
