import React from "react";
import { AppNavigation } from "./navigation";
import { ClientProvider } from "./context/ClientContext";

function App() {
  return (
    <ClientProvider>
      <AppNavigation />
    </ClientProvider>
  );
}

export default App;
