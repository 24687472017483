import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  NotFoundException,
  ChecksumException,
  FormatException,
} from "@zxing/library";
import { BrowserMultiFormatReader } from "@zxing/browser";
import { useLocalStorage } from ".";

/**
 * Hook que se encarga de proporcionar la funcion de decodificacion de codigos de barra.
 */
export const useDecode = (
  sendCodeToFetch: Function
): [() => void, () => void, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [{ cameraIndex }, storeCameraIndex] = useLocalStorage("camera_index", {
    cameraIndex: 0,
  });
  // const [
  //   videoDevicesStored,
  //   storeVideoDevices,
  // ] = useLocalStorage("video_devices", [{ deviceId: "0" }]);
  const [stop, setStop] = useState<boolean>(false);
  const [length, setLength] = useState<number>(1);
  const [videoDevicesList, setVideoDevicesList] = useState<Array<any>>([]);
  const codeReader = new BrowserMultiFormatReader();

  // useLayoutEffect(() => {
  //   // navigator.mediaDevices.enumerateDevices().then((devices) => {
  //   //   console.log(devices);
  //   // });
  //   // console.log(mediaDevices)
  //   // storeCameraIndex(0);
  //   BrowserMultiFormatReader.listVideoInputDevices()
  //     .then((videoDevices) => {
  //       console.log(videoDevices);
  //       setVideoDevicesList(videoDevices);
  //       setLength(videoDevices.length);
  //       // const videoDevicesFiltered = videoDevices.filter((item) =>
  //       //   item.label.includes("back")
  //       // );
  //       // setLength(videoDevicesFiltered.length);
  //       // console.log(videoDevicesFiltered);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  let lastCode: string = "";

  const decodeOnce = () => {
    // console.log(videoDevicesList);
    BrowserMultiFormatReader.listVideoInputDevices()
      .then((videoDevices) => {
        console.log(videoDevices);
        // setVideoDevicesList(videoDevices);
        setLength(videoDevices.length);
        // const videoDevicesFiltered = videoDevices.filter((item) =>
        //   item.label.includes("back")
        // );
        // setLength(videoDevicesFiltered.length);
        // console.log(videoDevicesFiltered);
        console.log(videoDevices[cameraIndex].deviceId.toString());
        codeReader.decodeFromVideoDevice(
          videoDevices[cameraIndex].deviceId.toString(),
          "video",
          async (result, err, control) => {
            if (stop) {
              console.log(stop);
              setStop(false);
              control.stop();
            }
            if (result) {
              if (lastCode !== result.getText())
                sendCodeToFetch(result.getText())
                  .then((code: any) => {
                    control.stop();
                  })
                  .catch(async (error: any) => {});
              lastCode = result.getText();
            }

            if (err) {
              // console.log(err);
              if (err instanceof NotFoundException) {
                // console.log("No barcode found.");
              }
              if (err instanceof ChecksumException) {
                console.log(
                  "A code was found, but it's read value was not valid."
                );
              }
              if (err instanceof FormatException) {
                console.log(
                  "A code was found, but it was in a invalid format."
                );
              }
            }
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeCamera = () => {
    if (length > 1) {
      const index = cameraIndex == length - 1 ? 0 : cameraIndex + 1;
      storeCameraIndex({ cameraIndex: index });
      window.location.reload();
    }
  };

  return [decodeOnce, changeCamera, setStop];
};
