import React, { useContext, useState } from "react";
import { Image } from "react-bootstrap";
import { CustomButton, StockVisualizer } from "../../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { parsePriceStr } from "../../../utils/utils";
import { config } from "../../../config";
import { BsArrowDownSquareFill } from "react-icons/bs";
import ClientContext from "../../../context/ClientContext";

import "../../../styles/CityKids/CityKidsProduct.css";

export interface Props {}

/**
 * Screen donde se muestra la informacion del producto
 * Cliente: CityKids
 */
export const CityKidsProductScreen: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [noProdImage, setNoProdImage] = useState<boolean>(false);
  const [scrollBtn, setScrollBtn] = useState<boolean>(true);
  const [clientData] = useContext(ClientContext);


  const navigate = useNavigate();

  const {
    state: { data },
  } = useLocation();

  const handleScroll = () => {
    setScrollBtn(false);

    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="citykids-product-container">
      <div className="citykids-prod-container">
        {!noProdImage ? (
          <Image
            className="prod-image"
            src={`${config.CLIENTS.URLIMG}/${data.sku}.jpg`}
            onError={(e) => {
              setNoProdImage(true);
            }}
          />
        ) : (
          <header>
            <Image
              className="client-logo"
              src={`${config.API.URL}/images/${clientData.name}.png`}
              onError={(e) => {
                e.currentTarget.src = `${config.API.URL}/images/logodefault.png`;
              }}
            />
          </header>
        )}
      </div>
      <div className="citykids-prod-data">
        <p className="citykids-prod-name-text">{data.name}</p>
        <p className="citykids-prod-data-text">{data.brand}</p>
        <p className="citykids-prod-data-text">{`SKU: ${data.sku}`}</p>
      </div>
      <div className="citykids-prod-price">
        <p>${parsePriceStr(parseFloat(data.price).toFixed(2))}</p>
      </div>
      {data.stock !== undefined ? (
        <div className="citykids-prod-stock">
          <p className="citykids-stock-title">
            Disponibilidad en nuestras tiendas
          </p>
          <StockVisualizer className="citykids-stock-visualizer" data={data} />
          <p className="citykids-stock-text">
            Stock sujeto a confirmación. Verificar disponibilidad con personal
            de la sucursal.
          </p>
        </div>
      ) : null}
      <hr className="divider"></hr>
      <div className="citykids-prod-back-btn-row">
        <CustomButton
          className="citykids-prod-back-btn"
          content="Consultar otro producto"
          show={true}
          click={() => navigate(`/home`)}
          style={{ fontSize: 20 }}
        />
      </div>
      {scrollBtn ? (
        <footer className="citykids-scrool-down-footer">
          <div className="scroll-down-btn-container">
            <BsArrowDownSquareFill
              className="citykids-btn-icon"
              onClick={handleScroll}
            />
          </div>
        </footer>
      ) : null}
    </div>
  );
};
