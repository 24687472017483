import React, { useContext, useEffect } from "react";
import { CodeScanner, CustomAlert, CustomButton } from "../../../components";
import { Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { config } from "../../../config";

import { useFetchProduct, useDecode } from "../../../hooks";

import "../../../styles/CityKids/CityKidsHome.css";
import ClientContext from "../../../context/ClientContext";
export interface Props {}

/**
 * Screen principal
 * Cliente: CityKids
 */
export const CityKidsHomeScreen: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [fetchProduct, showAlert, alertMsg] = useFetchProduct();
  const [clientData] = useContext(ClientContext);

  const navigate = useNavigate();
  const [decodeOnce, changeCamera, setStop] = useDecode(fetchProduct);

  useEffect(() => {
    decodeOnce();
  }, []);

  return (
    <div className="border">
      <header className="client-logo-header">
        <Image
          className="client-logo"
          src={`${config.API.URL}/images/${clientData.name}.png`}
          onError={(event) => {
            event.currentTarget.src = `${config.API.URL}/images/logodefault.png`;
          }}
        />
      </header>
      <div className="citykids-home-container">
        <CustomButton
          click={changeCamera}
          content="Cambiar camara"
          show={true}
          className="camera-selector-btn"
        />
        <CustomAlert variant="danger" show={showAlert} content={alertMsg} />
        <div className="citykids-scanner-text">
          <p>Para ver el precio escaneá el código</p>
        </div>
        <div className="citykids-scanner-row">
          <hr className="line-camera-indicator"></hr>
          <div className="box-camera-indicator"></div>
          <CodeScanner
            className="citykids-code-scanner"
            height={350}
            width={320}
          />
        </div>
        <hr className="divider"></hr>
        <div className="citykids-manual-button">
          <CustomButton
            style={{ fontSize: 20 }}
            content={`Ingrese el código del producto`}
            click={() => {
              setStop(true);
              navigate(`/manualcode`);
            }}
            show={true}
            className="citykids-code-btn"
          />
        </div>
      </div>
    </div>
  );
};
